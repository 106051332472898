import React, { useEffect,useState } from "react";
import { ThankYou } from "../components";
import { OrderSummary } from "../../cart/components";
import { useNavigate } from "react-router-dom";

const OrderPlaced = () => {
  const navigate = useNavigate();
  const [summaryList, setSummaryList] = useState(JSON.parse(localStorage.getItem('summary')));

  useEffect(() => {
    // Flush cart and selection after order has been submitted
    
    if (!summaryList || summaryList.length > 0) {
      localStorage.removeItem("order_id");
      localStorage.removeItem("summary");
      localStorage.setItem("shopping", JSON.stringify({ products: {} }));
    }
  }, [summaryList]);


  const handlePageReload = () => {
    localStorage.setItem('summary',JSON.stringify(summaryList))
      return 
  }

  useEffect(()=>{
    window.addEventListener('beforeunload', handlePageReload)
    return () => {
      window.removeEventListener("beforeunload" ,handleBeforePageReload)    };
  },[])


  const handleBeforePageReload=()=>{
    localStorage.removeItem('summary' )
  }

  return (
    <>
      <ThankYou />
      <OrderSummary list={JSON.parse(localStorage.getItem("summary"))} />
    </>
  );
};

export default OrderPlaced;




