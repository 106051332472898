import cart from "assets/icons/cart.png";
import plus from "assets/icons/plus.png";
import search from "assets/icons/search.png";
import subtract from "assets/icons/subtract.png";
import logo from "assets/icons/logo.png";
import vector from "assets/icons/vector.png";
import arrow from "assets/icons/arrow.png";
import share from "assets/icons/share.png";
import reset from "assets/icons/reset.png";
import imageshare from "assets/icons/imageshare.png";

export const frontEndPoints = {
  default: "/",
  home: "/home",
  cart: "/cart",
  orderPlaced: "/order-placed",
  test: "/test",
};

export const icons = {
  cart,
  plus,
  search,
  subtract,
  logo,
  arrow,
  vector,
  share,
  reset,
  imageshare
};
