import React from "react";
import "./themeProvider.scss"

const ThemeProvider = ({children}) => {
    return(
        <>
            {children}
        </>
    )
}

export default ThemeProvider;