import React from "react";
import { Box } from "@chakra-ui/react";

const ScrollContainer = ({ maxH, children,...props }) => {
  return (
    <Box w="100%" maxH={maxH} overflowY={"auto"} overflowX={"hiddens"} {...props}>
      {children}
    </Box>
  );
};

export default ScrollContainer;
