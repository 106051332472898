import React from "react";
import { Home, Cart, OrderPlaced,Test } from "layouts";
import { frontEndPoints } from "utils";
import { ProductCard } from "../layouts/home/components";

const routes = [
    {
        path: frontEndPoints.default,
        element : <Home />,
    },
    {
        path: frontEndPoints.home,
        element : <Home />,
    },
    {
        path: frontEndPoints.productCard,
        element : <ProductCard/>,
    },

    {
        path: frontEndPoints.cart,
        element : <Cart />,
    },
    {
        path: frontEndPoints.orderPlaced,
        element : <OrderPlaced/>
    },
    {
        path: frontEndPoints.test,
        element : <Test />,
    },
]

export default routes;