import React, { useState } from "react";
import {
  Table as T,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  HStack,
  Input,
  Button,
  Flex,
  Box,
  Alert,AlertIcon
} from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";

const Table = ({ tableList, onIncrement, onDecrement, onCancel }) => {


  const [showError, setShowError] = useState(false);

  const handleInputBlur = (e) => {
    const value = e.target.value.trim();
    if (value === "" && !e.currentTarget.contains(e.relatedTarget)) {
      setShowError(true);
    } else {
      setShowError(false);
    }
  };
  const handleCloseError = () => {
    setShowError(false);
  };

  const getTotalValue = () => {
    let tc = 0;
    tableList.forEach((ls) => {
      tc = tc + ls.offerPrice * ls.pcs;
    });
    return tc;
  };

  return (
    <Box overflowX="auto">
      <TableContainer>
        <T size="sm">
          <Thead>
            <Tr
              fontStyle="Roboto"
              fontWeight="400"
              fontSize="12px"
              color="#000000"
              bgColor="#F5F5F5"
            >
              <Th textAlign="center">Size</Th>
              <Th textAlign="center">Qty(P)</Th>
              <Th textAlign="center">Total</Th>
            </Tr>
          </Thead>
          <Tbody>
            {tableList.map((d, index) => (
              <Tr key={index}>
                <Td textAlign="center" pt="0px" pb="0px">
                  {d.size}
                </Td>
                <Td textAlign="center" pt="0px" pb="0px">
                  <HStack justifyContent="center">
                    <Button
                      h="14px"
                      minWidth="14px"
                      min={1}
                      p={0}
                      marginBottom="3px"
                      borderRadius="50% !important"
                      onClick={(e) => {
                        onDecrement({
                          code: `${d.code} ${d.color}`,
                          size_id: d.size_id,
                          size: d.size,
                          pcs: d.pcs < 2 ? 1 : d.pcs - 1,
                        });
                      }}
                      bgColor="#D9D9D9"
                      fontSize="12px"
                    >
                      -
                    </Button>
                    <Input
                      onChange={(e) => {
                        let newPcs = Number(e.target.value);
                        onDecrement({
                          code: `${d.code} ${d.color}`,
                          size_id: d.size_id,
                          size: d.size,
                          pcs: newPcs < 1 ? "" : newPcs,
                        });
                      }}
                      onBlur={handleInputBlur}
                      maxLength="4"
                      size="xs"
                      maxW="50px"
                      minW="50px"
                      type="tel"
                      value={d.pcs}
                      min={1}
                      required
                    />
                    <Button
                      h="14px"
                      minWidth="14px"
                      p={0}
                      borderRadius="50% !important"
                      onClick={() => {
                        onIncrement({
                          code: `${d.code} ${d.color}`,
                          size_id: d.size_id,
                          size: d.size,
                          pcs: parseInt(d.pcs, 10) + 1,
                        });
                      }}
                      bgColor="#D9D9D9"
                      fontSize="12px"
                    >
                      +
                    </Button>
                  </HStack>
                </Td>
                <Td textAlign="center" pt="0px" pb="0px">
                  <Flex
                    justifyContent="center"
                    fontFamily="Roboto"
                    position="relative"
                  >
                    <Flex>
                      <span style={{ maxWidth: "" }}>
                        {d.offerPrice * d.pcs}
                      </span>
                    </Flex>
                    <Button
                      onClick={() =>
                        onCancel({
                          ucode: `${d.code} ${d.color}`,
                          size_id: d.size_id,
                          size: d.size,
                        })
                      }
                      background="red.400"
                      h="15px"
                      minWidth="15px"
                      ml="5px"
                      borderRadius="50% !important"
                      padding="0px"
                      position="relative"
                      zIndex="1"
                      _hover={{
                        opacity: 1,
                        background: "red.400",
                      }}
                      _active={{
                        background: "red.400",
                      }}
                    >
                      <CloseIcon width="0.4rem" color="#fff" />
                    </Button>
                  </Flex>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </T>
      </TableContainer>
      <Flex w="100%" p={4}>
        <Flex w="66%" justifyContent="start" fontFamily="Roboto">
          Sub total
        </Flex>
        <Flex
          w="33%"
          justifyContent="center"
          marginLeft="16px"
          fontFamily="Roboto"
        >
          {getTotalValue()}
        </Flex>
      </Flex>

   

{showError && (
        <Alert status="error" mb={4}>
          <AlertIcon />
          Quantity field is required
        </Alert>
      )}
    </Box>
  );
};

export default Table;
