import { useState, useRef, useEffect } from "react";
import {
  InputGroup,
  InputRightElement,
  Input,
  Box,
  Flex,
  Center,
  Text,
  useDimensions,
  Divider,
} from "@chakra-ui/react";
import classNames from "classnames/bind";
import styles from "./selectBox.module.scss";
import { ChevronDownIcon } from "@chakra-ui/icons";

const cx = classNames.bind(styles);

const SelectBox = (props) => {
  const [dropPosition, setDropPosition] = useState({});
  const { options, onChange, value, name, size, isNotSearchable, otherProps } =
    props;
  const [isDrop, setIsDrop] = useState(false);
  const [virtualOptions, setVirtualOptions] = useState(options);
  const [virtualValue, setVirtualValue] = useState(value ? value.label : "");

  const ref = useRef(null);
  const boxRef = useRef(null);
  const boxDimensions = useDimensions(boxRef);

  const params = new URLSearchParams(window.location.search)

  useEffect(() => {
  console.log(params.get(`category`))
  }, [])

  useEffect(() => {
    setVirtualOptions(options);
  }, [options]);

  useEffect(() => {
    setVirtualOptions(options);
  }, [options]);

  useEffect(() => {
    const handleEvent = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsDrop(false);
        setVirtualValue(value?.label);
        setVirtualOptions(options);
      }
    };
    document.addEventListener("mousedown", handleEvent);
  });

  useEffect(() => {
    setVirtualValue(value ? value.label : "");
  }, [value]);

  const handleSearch = (data) => {
    let vopts = getSearchList(data, options);
    setVirtualOptions(vopts);
  };

  const handleDropPosition = () => {
    setDropPosition(
      window.innerHeight - ref.current?.getBoundingClientRect().bottom < 300
        ? {
            bottom:
              window.innerHeight - ref.current?.getBoundingClientRect().top,
          }
        : { top: ref.current?.getBoundingClientRect().bottom }
    );
  };

  const getSearchList = (value, list, key = "label") => {
    if (value.trim().length > 0 && value) {
      return list.filter((item) => {
        return item[key].toLowerCase().includes(value.toLowerCase());
      });
      
    } else {
      return list;
    }
    
  };
  
  return (
    <Box position="relative" width="100%" ref={ref}>
      <InputGroup size={size ?? "sm"} ref={boxRef}>
        <Input
          type="text"
          placeholder={props.placeholder}
          onClick={() => {
            setIsDrop(true);
            handleDropPosition();
          }}
          onChange={(event) => {
            setVirtualValue(event.target.value);
            handleSearch(event.target.value);
          }}
          color="var(--text-primary)"
          // value={virtualValue}
          // value={props.placeholder==="Category"?params.get(`category`):virtualValue || props.placeholder==="Brands"?params.get(`brand`):virtualValue}
          value={props.value}
          bg="var(--complementary-white)"
          isReadOnly={isNotSearchable}
          border="1px solid rgba(34, 34, 34, 0.02);"
          borderRadius="6px"
          _focus={{
            outline: "none", 
          }}
          _placeholder={{
            color: "var(--text-primary)",
          }}
          {...otherProps}
        />

        <InputRightElement pointerEvents="none">
          <ChevronDownIcon
            stroke="var(--text-primary)"
            strokeWidth={3}
            style={{ transform: `rotate(${isDrop ? "180deg" : "0deg"})` }}
          />
        </InputRightElement>
      </InputGroup>
      {isDrop && (
        <Box
          w={"200%"}
          p={1}
          background="#fff"
          position="absolute"
          zIndex={1024}
          borderRadius={6}
          boxShadow="rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px"
          {...(props.position ? props.position : {})}
          // {...dropPosition}
        >
          <Box maxH="300px" overflowY={"auto"}>
            {virtualOptions?.map((vopt) => (
              <Flex
                p={2}
                fontFamily={"Roboto"}
                fontSize="11px"
                borderBottom={"1px solid rgba(0,0,0,0.4)"}
                className={cx([
                  "options",
                  value?.value === vopt?.value ? "active" : "",
                ])}
                onClick={() => {
                  setIsDrop((prev) => !prev);
                  setVirtualValue(vopt?.label);
                  onChange({ target: { value: vopt, name } });
                }}
                key={vopt?.value}
              >
                <Text type="linkText">{vopt.label}</Text>
              </Flex>
            ))}
          </Box>

          {virtualOptions?.length === 0 && (
            <Box p={4}>
              <Center>
                <Text
                  fontFamily={"Roboto"}
                  fontSize="11px"
                  type="linkText"
                  color="var(--text-caption)"
                >
                  Can&#39;t find any options !
                </Text>
              </Center>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

export default SelectBox;
