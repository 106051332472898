import React, { useState, useRef } from "react";
import {
  Center,
  Container,
  Flex,
  Image,
  Spacer
} from "@chakra-ui/react";
import "./header.css";
import { Input,SelectBox, Button , ScrollContainer} from "components";
import { icons } from "utils";
import logo from "../../../../assets/images/fawz_logo.jpg";
import { RWebShare } from "react-web-share";
import { useEffect } from "react";


const Header = ({
  brands,
  categories,
  selectedBrand,
  selectedCategory,
  searchValue,
  onBrandChange,
  onCategoryChange,
  onReset,
  isCartNotify,
  cartNotifyCount,
  onSearch,
  onCartClick,
  onSearchValue,
  onCloseSearch
}) => {
const [isInputVisible,setInputVisible] = useState(false)




const searchField = useRef(null)
console.log('searchField',searchField)

const scrollToTop = () => {
  window.scrollTo(0, 0);
};

const handleKeyDown = (event) => {
  if (event.key === 'Enter') {
    onSearch(searchValue);
    event.preventDefault();
    event.target.blur();
  }
};


  return (
    <Container h={"110px"} position="fixed" bg={"#fff"} zIndex={1024}>

      <Flex w="100%" justifyContent={"space-between"} pt={2.5}>
        <Center>
          <Image src={logo} width={"50px"} />
        </Center>
        <Center w="70%">
          {/* <form
            onSubmit={(e) => {
              e.preventDefault();
              console.log('onsubmit')
              onSearch(searchValue);
              setInputVisible(false);
              document.getElementById('search')?.blur();
            }}
            style={{ width: "100%" }}
          > */}
<form style={{ width: "100%" }}>
            <Input
              id='search'
              type="text"
              isSearchable
              setInputVisible={setInputVisible}
              isInputVisible={isInputVisible}
              // value={searchValue}
              onClose={()=>onCloseSearch && onCloseSearch()}
              onKeyDown = {handleKeyDown}
              onKeyPress = {handleKeyDown}
              name={"search"}        
              onChange={(event) => {
                onSearchValue(event);
                console.log('onchange')
                setInputVisible(false); 
              }}
              placeholder="Search product here....  "
            />
</form>
          {/* </form> */}
          <span onClick={() => onCartClick()}>
            <Button
              background="white"
              w="26px"
              isNotify={isCartNotify}
              notifyCount={cartNotifyCount}
              border={"none"}
              // onClick={() => onCartClick()}
            >
              <Image ml="0px" className="heading" src={icons.cart} />
            </Button>
          </span>
        </Center>
      </Flex>
      <Flex w="100%" justifyContent={"space-between"} mt={3}>
       
        <Center w="40%"  marginRight='7px'>
          <SelectBox
            options={categories}
            value={selectedCategory}
            name={"category"}
            position={{ left: 0 }}
            onChange={(event) => onCategoryChange(event)}
            fontFamily={"Roboto"}
            placeholder="Category"
          />
        </Center>
        <Spacer  />
      
      
     
        <Center w="35%" marginRight='-10px'>
          <SelectBox
            options={brands}
            name={"brand"}
            value={selectedBrand}
            onChange={(event) => {
              onBrandChange(event);
            }}
            position={{ left: 0 }}
            fontFamily={"Roboto"}
            placeholder="Brands"
          />
        </Center>
  
        <Spacer />
        <Center>
          
          {/* <Image
            w="24px"
            h="24px"
            cursor={'pointer'}
            onClick={() => {
              onReset();
            }}
            ml="0px"
            className="heading"
            src={icons.reset}
          /> */}

          <Button
            onClick={() => {
              onReset();
            }}
            w='68px'
            marginLeft='24px'
          >
            Reset
          </Button>
        </Center>
        <Spacer />

        <Center>
          <RWebShare
            data={{
              text: "you can find here your shoes",
              url: `${window.location.href}`,
              title: "Bootwala",
            }}
            onClick={() => console.log("shared successfully!")}
          >
            <Image
              ml="2px"
              w="20px"
              h="20px"
              className="heading"
              src={icons.share}
            />
          </RWebShare>
        </Center>
      </Flex>
    </Container>
  );
};

export default Header;
