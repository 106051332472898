import React from "react";
import { Routes as R, Route } from "react-router-dom";
import routes from "./config";
import { MobileView } from "layouts";

const Routes = () => {
    return (  
        <MobileView>
             <R>
                {
                    routes.map((route, routeIndex) => (
                        <Route {...route} key={routeIndex}></Route>
                    ))
                }
            </R>
        </MobileView>
    )
}

export default Routes;