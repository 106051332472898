import React from "react";
import { Box, Center } from "@chakra-ui/react";

const MobileView = ({ children }) => {
  return (
    <Center w="100%" h="100vh" alignItems="center" marginRight="50px">
      <Box width="100%" maxW={"480px"} height="100vh">
        {children}
      </Box>
    </Center>
  );
};

export default MobileView;
