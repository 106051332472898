import React from "react";
import { Box, Image, Flex, Text, Grid, GridItem } from "@chakra-ui/react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
const BannerCard = ({
  bannerData
}) => {

  const settings = {
    infinite: true,
    dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyLoad: true,
    autoplay: true,
  autoplaySpeed: 3000,
   
  };
  return (
    <Box
      width="100%"
      overflow="hidden"
      justifyContent="center"
      alignItems="center"
      paddingBottom={6}
      marginBottom={4}
    >
        <Slider {...settings}>
          {bannerData.map((item) => (
        
      <Flex
        width="100%"
        direction={"column"}
        justifyContent="center"
        alignItems="center"
        backgroundColor="#F5F5F5"
        maxH="100px"
        borderTop={"1px solid #4A72FF"}
        onClick={()=>{if(item?.target_link){window.open(item?.target_link, "_blank")}}}
      >
        <Image src={item?.banner} alt="" style={{objectFit:"cover"}} width={"100%"} height={"100%"} />
      </Flex>
       ))}
        </Slider>
    </Box>
  );
};

export default BannerCard;
