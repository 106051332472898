import React, { useEffect, useRef } from "react";
import { Input as I, Text, Flex, Box, Center } from "@chakra-ui/react";
import { SearchIcon, CloseIcon } from "@chakra-ui/icons";
import { useState } from "react";
import { RenderIf } from "components";

const Input = ({ label, isSearchable, onClose, ...props }) => {
  const [isInput, setIsInput] = useState(isSearchable ? false : true);
  const inputRef = useRef(null);

  useEffect(()=>{
    if(inputRef && isInput && isSearchable){
      inputRef.current?.focus()
    }
  },[isInput])
  // useEffect(()=>{
    
  //   setIsInput(props.isInputVisible);
  // //  props.setInputVisible(!props.isInputVisible)

  //   console.log('isInput',isInput,'onEnter value',props.isInputVisible)
  // },[props.isInputVisible])
  return (
    <Flex position={"relative"} width="100%">
      <RenderIf isTrue={label && !isSearchable}>
        <Text>{label}</Text>
      </RenderIf>
      <RenderIf isTrue={isInput}>
        <Center w="100%">
          <I w="100%" {...props}  ref={inputRef} id='serach' size="sm" />
          {/* <I w="100%" {...props} autoFocus size="sm" /> */}

        </Center>
      </RenderIf>

      <RenderIf isTrue={isSearchable}>
        <Center>
          <Box
            borderColor="#4A72FF"
            color="#767676"
            cursor={"pointer"}
            onClick={() => {setIsInput((prev) => !prev);
            // console.log("onclick")
            //   props.setInputVisible(isInput)
            //  isInput && onClose()
            onClose()

            }}
            position={"absolute"}
            right={"10px"}
            zIndex={"5"}
          >
            <RenderIf isTrue={isInput}>
              <CloseIcon color="#4A72FF" width={"0.8rem"} height={"0.8rem"} />
            </RenderIf>

            <RenderIf isTrue={!isInput}>
              <label htmlFor={props.name}>
                <SearchIcon
                  color={"#4A72FF"}
                  width={"1.3rem"}
                  height={"1.3rem"}
                />
              </label>
            </RenderIf>
          </Box>
        </Center>
      </RenderIf>
    </Flex>
  );
};

export default Input;
