import { ChakraProvider } from "@chakra-ui/react";
import { ThemeProvider } from "components";
import { useEffect } from "react";
import Routes from "routes";
function App() {
  useEffect(() => {
    if (!localStorage.getItem("shopping")) 
    {
      localStorage.setItem(
        "shopping",
        JSON.stringify({
          products: {},
        })
      );
    }
  
  });

  return (
    <ChakraProvider>
      <ThemeProvider>
        <Routes />
      </ThemeProvider>
    </ChakraProvider>
  );
}

export default App;
