import { Box } from '@chakra-ui/react';
import React from 'react';
import { SelectBox, Input, Table, Modal } from "../../components";
import { ProductCard } from "../home/components";
import OrderDetails from '../orderPlaced/components/orderDetails/orderDetails';
import ThankYou from '../orderPlaced/components/thankYou/thankYou';


const Test = () => {
    return (
 <Box>
     <Modal/>
     {/* <Table/> */}
     {/* <ThankYou/> */}
     {/* <OrderDetails/> */}


        {/* <Input marginLeft="5px" fontFamily="RaleWay" fontWeight="400"
    fontStyle="Regular" fontSize="14px"
    color="#767676"
    placeholder="Select here" label="Name"
    isSearchable />
    
    <div>
    <Input marginLeft="5px" fontFamily="RaleWay" fontWeight="400"
        fontStyle="Regular" fontSize="14px"
        color="#767676" width="465px"
        placeholder="Enter Your Name" />
</div>

<Table maxW='100px' mr='2rem' justifyContent={"center"} justifySelf={"center"}/>
<div >
    <Flex width="300px" marginLeft="5px" fontFamily="Raleway"
        fontStyle="Regular" fontSize="14px" textAlign={"center"} color="#767676" >
        <SelectBox options={[{
            value: "Sports",
            label: "Sports",
        },
        {
            value: "Formal",
            label: "Formal",
        },
        {
            value: "Casual",
            label: "Casual",
        },]}
        />
    </Flex>

    <div>
        <Flex width="300px" marginLeft="5px" fontFamily="Raleway"
            fontStyle="Regular" fontSize="14px" textAlign={"center"} color="#767676" >
            <SelectBox options={[{
                value: "Sneaker",
                label: "Sneaker",
            },
            {
                value: "Nike",
                label: "Nike",
            },
            {
                value: "Adidas",
                label: "Adidas",
            },
            {
                value: "Puma",
                label: "Puma",
            },]}
            />
        </Flex>
    </div>
</div>
<ProductCard /> */}
</Box>
    )
}

export default Test;