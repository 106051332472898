import React from "react";
import {
  Modal as M,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalCloseButton,
  ModalHeader,
} from "@chakra-ui/react";

const Modal = ({ isOpen, onClose, title, footer, content }) => {

  return (
    <M
    width="100%"
    isOpen={isOpen}
    onClose={onClose}
    alignItems="center"
    direction="column"
  >
    <ModalOverlay />
    <ModalContent
      minH={"100px"}
      top="137px"
      w="350px"
      h="280px"
    >
      <ModalHeader>
        {title}
      </ModalHeader>

        {content}
      <ModalCloseButton />
      <ModalFooter>
        {footer}
      </ModalFooter>
    </ModalContent>
  </M>
  );
};

export default Modal;
