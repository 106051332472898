import { Box, Flex, Spacer, Heading } from "@chakra-ui/react";
import React from "react";

const OrderSummary = ({ list }) => {
  const getTotal = () => {
    let t = {
      pcs: 0,
      offerPrice: 0,
      price: 0,
    };
    list.forEach((ls) => {
          console.log(t);

      t.qty = (t.qty ? t.qty : 0) + ls.qty;
      t.pcs = parseInt(t.pcs, 10) + parseInt(ls.pcs, 10);

      t.price = t.price + ls.price;
    });
    console.log(t);
    return t;
  };
  return (
    <>
      <Box p={5} m={15} borderRadius="3%" bg="#DADADA">
        <Heading
          as="h5"
          size="md"
          pb={1}
          fontFamily={"Roboto"}
          borderBottom={"1px solid rgba(0,0,0,0.5)"}
        >
          Estimate
        </Heading>

        {list.map((ls, lsIndex) => (
          <Flex mt="10px" key={lsIndex}>
            <Box
              color="#767676"
              fontSize="10px"
              w={"30%"}
              fontFamily={"Roboto"}
            >
              {ls?.brand_name.split("_").join(" ")}
            </Box>
            <Box
              color="#767676"
              fontSize="10px"
              w={"30%"}
              fontFamily={"Roboto"}
            >
              {ls.name}
            </Box>
            <Spacer />
            <Box
              color="#767676"
              fontSize="10px"
              w={"20%"}
              fontFamily={"Roboto"}
              display={"flex"}
              justifyContent={"center"}
            >
              {ls.pcs}({ls.offerPrice})
            </Box>
            <Spacer />
            <Box
              color="#767676"
              fontSize="10px"
              w={"20%"}
              fontFamily={"Roboto"}
              display={"flex"}
              justifyContent={"flex-end"}
            >
              ₹ {ls.price}
            </Box>
          </Flex>
        ))}
        <Flex mt="10px">
          <Box
            fontStyle="bold"
            color="#000000"
            fontSize="14px"
            w={"60%"}
            fontFamily={"Roboto"}
          >
            Total
          </Box>
          <Spacer />
          <Box
            fontStyle="bold"
            color="#000000"
            fontSize="14px"
            w={"20%"}
            fontFamily={"Roboto"}
            display={"flex"}
            justifyContent={"center"}
          >
            {" "}
            {getTotal().pcs} P
          </Box>
          <Spacer />
          <Box
            fontStyle="bold"
            color="#000000"
            fontSize="14px"
            w={"20%"}
            display={"flex"}
            justifyContent={"flex-end"}
            fontFamily={"Roboto"}
          >
            ₹ {getTotal().price}
          </Box>
        </Flex>
      </Box>
    </>
  );
};

export default OrderSummary;
