import React from "react";
import {
  Box,
  Image,
  Flex,
  Text,
  Grid,
  GridItem,
  Spacer,
  Center,
} from "@chakra-ui/react";
import { Button } from "components";
import { RWebShare } from "react-web-share";
import { icons } from "utils";

const ProductCard = ({
  image,
  brand,
  name,
  color,
  priceText,
  sizes,
  onSizeClick,
  selectedSizes,
  code,
  moq,
  quantity
}) => {

  return (
    <Box
      width="100%"
      overflow="hidden"
      justifyContent="center"
      alignItems="center"
      paddingBottom={6}
    >
      <Flex
        width="100%"
        direction="column"
        justifyContent="center"
        alignItems="center"
        backgroundColor="#F5F5F5"
        // minH="300px"
        borderTop="1px solid #4A72FF"
      >
        <Image src={image} alt="" width="100%" height="auto" minH={'300px'}/>
      </Flex>

      <Flex width="100%" justifyContent="space-between" padding="12px">
        <Flex direction="column" justifyContent="space-between">
          <Text
            fontSize="14px"
            fontWeight="600"
            fontFamily="Roboto"
            textOverflow="ellipsis"
            
          >
            {name}
          </Text>
          <Text fontSize="12px" fontWeight="500" mt="4px" fontFamily="Roboto">
            Colour: <b>{color}</b> <span>&nbsp;&nbsp;&nbsp;&nbsp;MOQ: {moq}</span>
          </Text>
        </Flex>
        <Flex direction="column" justifyContent="center" alignItems="center" width="20%" >
          <Text fontSize="18px" fontWeight="bold" fontFamily="Roboto" >
            {priceText}
          </Text>
        </Flex>
      </Flex>

      <Grid templateColumns="repeat(3, 1fr)" gap={4}>
        {sizes.map((pair, pairIndex) => {
          // Replace with actual closing stock data for each size
          return (
            <GridItem key={pairIndex}>
              <Button
                isNotify={
                  selectedSizes.filter((sls) => sls.size_id === pair._id)
                    .length > 0
                }
                notifyCount={
                  selectedSizes.filter((sls) => sls.size_id === pair._id)
                    .length > 0 &&
                  selectedSizes.filter((sls) => sls.size_id === pair._id)[0]
                    ?.pcs
                }
                fontStyle="Roboto"
                onClick={() => {
                  onSizeClick(pair);
                }}
                style={{
                  width: "80%",
                  fontSize:'14px'
                }}
              >
                <span>{pair.size}</span>{" "}
                <span style={{ fontSize: "11px", marginLeft: "5px" }}>
                  ({pair.quantity} P)
                </span>
              </Button>
            </GridItem>
          );
        })}
      </Grid>
    </Box>
  );
};

export default ProductCard;
