import React, { useEffect, useState } from "react";
import { Header, ProductCard } from "../components";
import { ScrollContainer, RenderIf } from "components";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  Box,
  Text,
  useDisclosure,
  Center,
  Flex,
  Button, Spinner
} from "@chakra-ui/react";
import { ApiUrl } from "../../../services/instance/index";
import axios from "axios";
import {
  useNavigate,
  useSearchParams,
  createSearchParams,
  useLocation,
} from "react-router-dom";
import "./index.css";
import { BannerCard } from "../components/bannerCard";

const Home = () => {
  const [brands, setBrands] = useState([]);
  const location = useLocation();
  const [searchData, setSearchData] = useState("");
  const [currentList, setCurrentList] = useState([]);
  const [categories, setCategories] = useState([]);
  const [headerData, setHeaderData] = useState({
    brand: { value: "", label: "" },
    category: { value: "", label: "" },
    search: "",
  });
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [products, setProdcuts] = useState([]);
  const [selectedSizes, setSelectedSizes] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState({});
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [modalData, setModalData] = useState({
    numberOfQty: 1,
    comment: "",
  });
  const [bannerData, setBannerData] = useState([]);
  const [isLoad, setIsLoad] = useState(false);
  const [isInfiniteLoader, setIsInfiniteLoader] = useState(false);
  const [isScrollToErr, setIsScrollTOErr] = useState(true);
  const [addedProducts, setAddedProducts] = useState({});
  const [offset, setOffset] = useState(0);


  useEffect(() => {
    const shopping = JSON.parse(localStorage.getItem("shopping"));
    setAddedProducts(shopping ? shopping.products : {});
  }, []);

  useEffect(() => {
    console.log(selectedProduct);
  }, [selectedProduct]);

  useEffect(() => {
    let sltdSzs = [];
    Object.entries(addedProducts).map(
      ([addedProductKey, addedProductValue], addedProductIndex) => {
        sltdSzs.push({
          size_id: addedProductKey,
          pcs: addedProductValue.pcs,
        });
      }
    );
    setSelectedSizes(sltdSzs);
  }, [addedProducts]);

  useEffect(() => {
    console.log(selectedSizes);
  }, [selectedSizes]);

  useEffect(() => {
    setSelectedProduct((prev) => {
      prev = { ...prev };
      prev.qty = modalData.numberOfQty;
      prev.desc = modalData.comment;
      return prev;
    });
  }, [modalData]);

  useEffect(() => {
    let params = {
      category: searchParams.get("category")
        ? categories.find((cat) => cat.value === searchParams.get("category"))
        : { value: "", label: "" },
      brand: searchParams.get("brand")
        ? brands.find((b) => b.value === searchParams.get("brand"))
        : { value: "", label: "" },
      search: searchParams.get("search") ? searchParams.get("search") : "",
    };
    setHeaderData(params);
    setOffset(0)
  }, [searchParams]);


  useEffect(() => {
    scrollToTop()
    setIsLoad(true);
    axios({
      method: "GET",
      url: `${ApiUrl}v1/products?brand=${searchParams.get("brand") ?? ""
        }&category=${searchParams.get("category") ?? ""}&search=${searchParams.get("search") ?? ""
        }&offset=${0}&code=${searchParams.get("code") ?? ""}&color=${searchParams.get("color") ?? ""}
       &size=${searchParams.get("size") ?? ""}  `,
    })
      .then((res) => {
        setIsLoad(false);
        setCurrentList(res.data.data);
        setProdcuts(res.data.data);
      })
      .catch((err) => {
        setIsLoad(false);

        console.log(err);
      });
  }, [searchParams]);

  useEffect(() => {
    //banners
    axios({
      method: "GET",
      url: `${ApiUrl}v1/get-banners`,
    }).then((res) => {
      console.log(res)
      setBannerData(res.data.data)
    }).catch((err) => {
      console.log(err)
    })


  }, [])




  useEffect(() => {
    //Brands
    axios({
      method: "GET",
      url: `${ApiUrl}v1/brands`,
    })
      .then((res) => {
        let copiedBrands = [];
        res.data.data.forEach((brand) => {
          copiedBrands.push({
            value: brand,
            label: brand.split("_").join(" "),
          });
        });
        setBrands(copiedBrands);
      })
      .catch((err) => {
        console.log(err);
      });
      axios({
        method: "GET",
        url: `${ApiUrl}v1/categories`,
      })
        .then((res) => {
          let copiedCategories = [];
          res.data.data.forEach((category) => {
            copiedCategories.push({
              value: category,
              label: category.split("_").join(" "),
            });
          });
          setCategories(copiedCategories);
        })
        .catch((err) => {
          console.log(err);
        });
  }, []);

  const handleHeaderData = (event) => {
    let params = {
      brand: searchParams.get("brand") ? searchParams.get("brand") : "",
      category: searchParams.get("category")
        ? searchParams.get("category")
        : "",
      // search: searchParams.get("search") ? searchParams.get("search") : "",
    };
    params[event.target.name] = ["brand", "category"].includes(
      event.target.name
    )
      ? event.target.value.value
      : event.target.value;
    // setHeaderData(params)
    navigate({
      pathname: "",
      search: createSearchParams({
        ...params,
      }).toString(),
    });
  };

  const handleSearch = (event) => {

    //  document.getElementById("brand").value=""
    let params = {
      // brand: searchParams.get("brand") ? searchParams.get("brand") : "",
      // category: searchParams.get("category")
      //   ? searchParams.get("category")
      //   : "",
      search: searchParams.get("search") ? searchParams.get("search") : "",
    };
    params.search = event;
    navigate({
      pathname: "",
      search: createSearchParams({
        ...params,
      }).toString(),
    });
  };

  const resetHeaderData = () => {
    Array.from(document.querySelectorAll("input")).forEach(
      Input => (Input.value = "")
    );
    setSearchData("");
    navigate({
      pathname: "",
      search: createSearchParams({
        ...{},

      }).toString(),
    });
  };

  const getPriceText = (salePrice, offerPrice, discount, discountPrice) => {
    if (parseInt(offerPrice) > 0) {
      return (
        <Box w="100%">
          <Flex w="100%" justifyContent="center">
            <Text fontFamily={"Roboto"}>₹ {offerPrice}</Text>
          </Flex>
          {discountPrice > 0 && (
            <Text fontFamily={"Roboto"} fontSize="small">
              <span
                style={{
                  textDecoration: "line-through",
                  color: "rgba(0,0,0,0.5)",
                }}
              >
                {salePrice}
              </span>
              <span style={{ color: "var(--yellow)" }}> {discount} off</span>
            </Text>
          )}
        </Box>
      );
    } else {
      return <Text fontFamily={"Roboto"}>₹ {salePrice}</Text>;
    }
  };

  const getDefaultMoq = (moq) => {
    if (typeof moq === 'string' && moq.trim() === ""){
      return 1
    }else if(moq===0){
      return 1
    }else{
      return moq
    }
  }

  const handleAddAndProduct = (data) => {
    console.log(data);
    let duplicateAddedProducts = { ...addedProducts };
    if (!duplicateAddedProducts[data.size_id]) {
      duplicateAddedProducts[data.size_id] = { ...data, qty: 1, pcs: getDefaultMoq(data.moq),moq:getDefaultMoq(data.moq) };
    } else {
      duplicateAddedProducts[data.size_id].qty =
        duplicateAddedProducts[data.size_id].qty + 1;
      duplicateAddedProducts[data.size_id].pcs =
        duplicateAddedProducts[data.size_id].qty *
        duplicateAddedProducts[data.size_id].moq;
    }
    console.log(duplicateAddedProducts)
    setAddedProducts(duplicateAddedProducts);
    let shopping = JSON.parse(localStorage.getItem("shopping"));
    shopping.products = duplicateAddedProducts;
    localStorage.setItem("shopping", JSON.stringify(shopping));
  };



  const handleScroll = () => {
    setIsInfiniteLoader(true)
    setIsScrollTOErr(true)
    axios({
      method: "GET",
      url: `${ApiUrl}v1/products?brand=${searchParams.get("brand") ?? ""
        }&category=${searchParams.get("category") ?? ""}&search=${searchParams.get("search") ?? ""
        }&offset=${offset + 1}`,
    })
      .then((res) => {
        setIsInfiniteLoader(false)
        setIsScrollTOErr(false)
        setProdcuts((prev) => {
          return [...prev, ...res.data.data];
        });
        setCurrentList(res.data.data);
        setOffset((prev) => prev + 1);
      })
      .catch((err) => {
        setIsScrollTOErr(true)
        setIsInfiniteLoader(true)
        console.log(err);
      });
  };
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };





  return (
    <>
      <RenderIf isTrue={isLoad}>
        <Center
          style={{
            height: "100vh",
            background: "rgba(0,0,0,0.5)",
            width: "100%",
            position: "fixed",
            zIndex: "1024",
          }}
        >
          <div className="lds-ripple">
            <div></div>
            <div></div>
          </div>
        </Center>
      </RenderIf>




      <Header
        categories={categories}
        brands={brands}
        onBrandChange={(event) => handleHeaderData(event)}
        onCategoryChange={(event) => handleHeaderData(event)}
        onSearch={(event) => {
          handleSearch(event);
        }}
        // onCloseSearch={() => {navigate('')}}
        // onCloseSearch={() => navigate('')}
        onSearchValue={(event) => setSearchData(event.target.value)}
        onReset={() => resetHeaderData()}
        // selectedBrand={headerData?.brand}
        // selectedCategory={headerData?.category}
        selectedBrand={headerData?.brand?.value}
        selectedCategory={headerData?.category?.value}
        isCartNotify={Object.keys(addedProducts).length > 0}
        cartNotifyCount={
          Object.keys(addedProducts).length > 0 &&
          Object.keys(addedProducts).length
        }
        searchValue={searchData}
        onCartClick={() => navigate("/cart")}
        onShare={() => {
          console.log(location.pathname);
        }}
      />

     

      <ScrollContainer marginTop={"110px"}>
        <InfiniteScroll
          dataLength={products.length}
          next={() => handleScroll()}
          hasMore={true}
          loader={
            ""
          }
          endMessage={
            <p style={{ textAlign: 'center' }}>
              <b>There is no more options available</b>
            </p>
          }
        >
          <BannerCard
            bannerData={bannerData}
          />
          {products?.map((product, productIndex) => (
            <ProductCard
              key={productIndex}
              image={product?.image}
              color={product?.color}
              moq={getDefaultMoq(product?.moq)}
              brand={product?.brand}
              name={product?.name}
              priceText={getPriceText(
                product?.salePrice,
                product?.offerPrice,
                product?.discount,
                product?.discountPrice
              )}
              sizes={product?.sizes}
              onSizeClick={(data) => {
                handleAddAndProduct({
                  ...product,
                  size_id: data["_id"],
                  size: data.size,
                });
              }}
              selectedSizes={selectedSizes}
              code={product?.code}
            />
          ))}

        </InfiniteScroll>

        <RenderIf isTrue={isInfiniteLoader} >
          <RenderIf isTrue={isScrollToErr}>
            <span style={{ display: "flex", justifyContent: "center" }}>
              <Center>
                <Spinner />
              </Center>
            </span>
            <Center>  <Text>Hang on,loading content</Text></Center>
          </RenderIf>
          {/* <Center>
            <Button marginTop={"10px"} onClick={scrollToTop} isTrue={isScrollToErr}>
              Go to top
            </Button>
          </Center> */}
        </RenderIf>



        {products?.length === 0 && isLoad == false && (
          <Text w="100%" fontFamily={"Roboto"} textAlign={"center"} p={10}>
            Opps! products are not available
          </Text>
        )}



        <RenderIf isTrue={!isInfiniteLoader && products?.length > 0}>
          <Flex
            direction={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            w="100%"
            padding={"18px"}
          >
            <Text >There is no more options available</Text>
            {currentList.length === 0 && <Button marginTop={"10px"} onClick={scrollToTop} >
              Go to top
            </Button>}
          </Flex>
        </RenderIf>
      </ScrollContainer>

      {/*         
      <Modal
        data={selectedProduct}
        isOpen={isOpen}
        onClose={onClose}
        title={getModalTitle()}
        content={getContent()}
        footer={getFooter()}
      /> */}
    </>
  );
};

export default Home;
