import React from "react";
import { Button, Image, Heading, Flex } from "@chakra-ui/react";
import { icons } from "utils";

const Header = ({onArrowClick}) => {
    return(
        <Flex pl="4px" pt="15px" pb="10px">
            <Button background="white"onClick={() => onArrowClick()} >
                <Image   src={icons.arrow} maxW={"20px"}/>
            </Button>
            <Heading pt="6px" pl="4px" as="h4" size="md"  fontFamily={"Roboto"}>
                My Cart
            </Heading>
        </Flex>
    )
}

export default Header;